import { Button, Modal } from "react-bootstrap"
import React from "react"

export const PrivacyModal = (props: { show: boolean, onHide: any }
) => {
    return (
        <Modal
            {...props}
            size="xl"
            // dialogClassName="modal-90w"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h3>Zásady ochrany osobních údajů naší společnosti  </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Tyto zásady ochrany osobních údajů vysvětlují, jak naše organizace používá osobní údaje, které od vás shromažďujeme při používání našich webových stránek.</p>
                <h3>Témata:</h3>
                <ul>
                    <li>Jaké údaje shromažďujeme?</li>
                    <li>Jak shromažďujeme vaše údaje?</li>
                    <li>Jak budeme vaše údaje používat?</li>
                    <li>Jak vaše údaje ukládáme?</li>
                    <li>Marketing</li>
                    <li>Jaká jsou vaše práva na ochranu údajů?</li>
                    <li>Co jsou to soubory cookie?</li>
                    <li>Jak soubory cookie používáme?</li>
                    <li>Jaké typy souborů cookie používáme?</li>
                    <li>Jak spravovat své soubory cookie</li>
                    <li>Zásady ochrany osobních údajů jiných webových stránek</li>
                    <li>Změny našich zásad ochrany osobních údajů</li>
                    <li>Jak nás můžete kontaktovat</li>
                </ul>

                <h3>Jaké údaje shromažďujeme?</h3>
                <p>Naše společnost shromažďuje následující údaje:</p>
                <ul>
                    <li>Osobní identifikační údaje (jméno, e-mailová adresa, telefonní číslo atd.)</li>
                    <li>Informace o vašem projektu, specifikace, požadavky, technologie a architektura</li>
                </ul>

                <h3>Jak shromažďujeme vaše údaje?</h3>
                <p>Většinu údajů, které shromažďujeme, poskytujete naší společnosti přímo vy. Údaje shromažďujeme a zpracováváme, když:</p>
                <ul>
                    <li>Registrujete se online nebo si objednáte některý z našich produktů či služeb.</li>
                    <li>Dobrovolně vyplníte zákaznický průzkum nebo poskytnete zpětnou vazbu na některém z našich diskusních fór nebo prostřednictvím e-mailu.</li>
                    <li>Používáte nebo prohlížíte naše webové stránky prostřednictvím souborů cookie vašeho prohlížeče.</li>
                </ul>

                <h3>Jak budeme vaše údaje používat?</h3>
                <p>Naše společnost shromažďuje vaše údaje, abychom mohli:</p>
                <ul>
                    <li>Zpracovat vaši objednávku a spravovat váš účet.</li>
                    <li>Zasílat vám e-mailem speciální nabídky dalších produktů a služeb, o kterých si myslíme, že by se vám mohly líbit.</li>
                    <li>Jak vaše údaje ukládáme?</li>
                    <li>Naše společnost bezpečně ukládá vaše údaje na on-premisse nebo cloudových serverech. Jako cloudové služby používáme Digital Ocean, AWS, Azure a GCP.</li>
                    <li>Naše společnost uchovává vaše data po dobu 10 let. Po uplynutí této doby vaše data vymažeme.</li>
                </ul>

                <h3>Marketing</h3>
                <p>Naše společnost by vám ráda zasílala informace o našich produktech a službách, o kterých si myslíme, že by se vám mohly líbit. </p>
                <p>Pokud jste souhlasili se zasíláním marketingových sdělení, můžete se z nich vždy později odhlásit. </p>
                <p>Máte právo kdykoli zabránit tomu, aby vás společnost DevCity s.r.o. kontaktovala pro marketingové účely nebo aby vaše údaje poskytovala dalším členům skupiny DevCity s.r.o.. </p>
                <p>Pokud si již nepřejete být kontaktováni pro marketingové účely, klikněte <a href="https://www.devcity.tech/contact-forms/unsubscribe-form">zde</a>.</p>

                <h3>Jaká jsou vaše práva na ochranu osobních údajů? </h3>
                <p>Naše společnost by se ráda ujistila, že jste si plně vědomi všech svých práv na ochranu osobních údajů. Každý uživatel má následující práva:</p>
                <p><b>Právo na přístup</b> - máte právo požádat Naši společnost o kopie svých osobních údajů. Za tuto službu vám můžeme účtovat malý poplatek.</p>
                <p><b>Právo na opravu</b> - Máte právo požádat Naši společnost o opravu jakýchkoli údajů, o kterých se domníváte, že jsou nepřesné. Máte také právo požádat Naši společnost o doplnění informací, o kterých se domníváte, že jsou neúplné.</p>
                <p><b>Právo na výmaz</b> - Máte právo požadovat, aby Naše společnost za určitých podmínek vymazala vaše osobní údaje.</p>
                <p><b>Právo na omezení zpracování</b> - máte právo požadovat, aby Naše společnost za určitých podmínek omezila zpracování vašich osobních údajů.</p>
                <p><b>Právo vznést námitku proti zpracování</b> - za určitých podmínek máte právo vznést námitku proti zpracování vašich osobních údajů Naší společností.</p>
                <p><b>Právo na přenositelnost údajů</b> - máte právo požadovat, aby Naše společnost za určitých podmínek předala údaje, které jsme shromáždili, jiné organizaci nebo přímo vám.</p>
                <p>Zavolejte nám na číslo: +420 725 536 023</p>
                <p>Nebo nám napište na adresu: martin.bobek@devcity.tech</p>


                <h3>Soubory cookie</h3>
                <p>Soubory cookie jsou textové soubory umístěné ve vašem počítači za účelem shromažďování standardních informací o internetovém protokolu a informací o chování návštěvníků. Když navštívíte naše webové stránky, můžeme od vás automaticky shromažďovat informace prostřednictvím souborů cookie nebo podobných technologií.</p>
                <p>Další informace naleznete na webu allaboutcookies.org.</p>

                <h3>Jak používáme soubory cookie?</h3>
                <p>Naše společnost používá soubory cookie různými způsoby, aby zlepšila vaše zkušenosti s našimi webovými stránkami, včetně:</p>
                <ul>
                    <li>Udržování vašeho přihlášení</li>
                    <li>Porozumění tomu, jak používáte naše webové stránky</li>
                </ul>

                <h3>Jaké typy souborů cookie používáme?</h3>
                <p>Existuje řada různých typů souborů cookie, naše webové stránky však používají:</p>
                <ul>
                    <li><b>Funkční</b> - Naše společnost používá tyto soubory cookie k tomu, abychom vás na našich webových stránkách rozpoznali a zapamatovali si vaše dříve zvolené preference. Ty mohou zahrnovat, jaký jazyk preferujete a v jaké lokalitě se nacházíte. Používá se kombinace souborů cookie první strany a třetích stran.</li>
                    <li><b>Reklamní</b> - Naše společnost používá tyto soubory cookie ke shromažďování informací o vaší návštěvě našich webových stránek, o obsahu, který jste si prohlíželi, o odkazech, které jste následovali, a informací o vašem prohlížeči, zařízení a vaší IP adrese. Naše společnost někdy sdílí některé omezené aspekty těchto údajů s třetími stranami pro reklamní účely. Online údaje shromážděné prostřednictvím souborů cookie můžeme také sdílet s našimi reklamními partnery. To znamená, že když navštívíte jinou webovou stránku, může se vám zobrazovat reklama na základě vašich vzorců prohlížení na našich webových stránkách.</li>
                </ul>

                <h3>Jak spravovat soubory cookie</h3>
                <p>Svůj prohlížeč můžete nastavit tak, aby soubory cookie nepřijímal, a na výše uvedené webové stránce se dozvíte, jak soubory cookie z prohlížeče odstranit. V několika případech však v důsledku toho nemusí fungovat některé funkce našich webových stránek.</p>

                <h3>Zásady ochrany osobních údajů jiných webových stránek</h3>
                <p>Webové stránky naší společnosti obsahují odkazy na jiné webové stránky. Naše zásady ochrany osobních údajů se vztahují pouze na naše webové stránky, takže pokud kliknete na odkaz na jiné webové stránky, měli byste si přečíst jejich zásady ochrany osobních údajů.</p>

                <h3>Změny našich zásad ochrany osobních údajů</h3>
                <p>Naše společnost své zásady ochrany osobních údajů pravidelně kontroluje a veškeré aktualizace umisťuje na tuto webovou stránku. Tyto zásady ochrany osobních údajů byly naposledy aktualizovány 17. prosince 2021.</p>

                <h3>Jak nás můžete kontaktovat</h3>
                <p>Máte-li jakékoli dotazy týkající se zásad ochrany osobních údajů společnosti Our Company, údajů, které o vás uchováváme, nebo chcete-li uplatnit některé ze svých práv na ochranu osobních údajů, neváhejte nás kontaktovat.</p>
                <p>Pošlete nám e-mail na adresu: martin.bobek@devcity.tech</p>
                <p>Zavolejte nám: +420 725 536 023</p>

                <h3>Jak kontaktovat příslušný orgán</h3>
                <p>Pokud si přejete nahlásit stížnost nebo pokud máte pocit, že Naše společnost nevyřešila vaše obavy uspokojivým způsobem, můžete se obrátit na Úřad komisaře pro informace.</p>
                <p>Email: martin.bobek@devcity.tech</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="order-button custom-button  text-white  rounded-pill" onClick={() => props.onHide()}>Rozumím</Button>
            </Modal.Footer>
        </Modal>
    )
}