import React, { useState } from 'react'
import logo from '../assets/images/logo.svg'
import { ModalCookie } from './utils/cookie';
import { PrivacyModal } from './utils/privacy';

export const Footer = () => {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [privacyShow, setPrivacyShow] = useState<boolean>(false);

    return (
        <footer className='light-background'>
            <div className=' d-flex align-items-center px-0 px-lg-5'>
                <div className='container d-flex justify-content-end  align-items-center px-0 px-md-5 my-5  mx-3 mx-md-auto'>
                    <div className='px-0'><img src={logo} alt="logo pickit.cz" className="footer-img" /></div>
                    <div className="ms-auto d-flex flex-column flex-lg-row justify-content-end  align-items-center text-muted">
                        <p className='mb-0 me-2'>Všechna práva vyhrazena</p> <span className='d-none d-lg-block'>|</span>
                        <u onClick={() => setPrivacyShow(true)} className='mx-2 dark-color my-3 my-md-0 cursor-pointer'>Ochrana osobních údajů</u>  <span className='d-none d-lg-block'>|</span>
                        <u className='ms-2 dark-color cursor-pointer' onClick={() => setModalShow(true)}>Cookies</u>
                        <ModalCookie show={modalShow} onHide={() => setModalShow(false)} />
                        <PrivacyModal show={privacyShow} onHide={() => setPrivacyShow(false)} />
                    </div>
                </div>
            </div>
        </footer>
    )
}